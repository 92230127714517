import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/my-dealers',
    name: 'MyDealers',
    component: () => import('../views/MyDealers.vue')
  },

  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import('../views/Invitation.vue')
  },

  {
    path: '/auto-login',
    name: 'AutoLogin',
    component: () => import('../views/AutoLogin.vue')
  },
  {
    path: '/request-auto-login',
    name: 'RequestAutoLogin',
    component: () => import('../views/RequestAutoLogin.vue')
  },
  {
    path: '/profile',
    name: 'MyProfile',
    component: () => import('../views/MyProfile.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
