import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import router from '@/router';


Vue.use(VueCookies)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rep: '',
    endpoint: process.env.VUE_APP_ENDPOINT,
    baseEndpoint: process.env.VUE_APP_BASE_ENDPOINT,
    token: '',
    dealers: '',
    dealersTotalItems: 0,
    dealersTotalPages: 1,
  },

  actions: {

    checkIfSessionExists({ commit }) {
      const token = Vue.$cookies.get('token')

      if (token) {
        commit('setToken', token)
      }
    },

    async validateToken({ state, dispatch, commit }) {
      if (state.token) {
        const url = state.endpoint + '/me?api_token=' + state.token
        try {
          const { data } = await axios.get(url)
          commit('setRep', data)
        } catch (error) {
          dispatch('logout')
        }
      }
    },

    setToken({ commit }, token) {
      const expiresAt = 60 * 60 * 24 * 30 * 12 //1 year

      Vue.$cookies.set('token', token, expiresAt)
      commit('setToken', token)
    },

    autoLogin({ commit },) {
      const token = router.currentRoute.query.token
      const timestamp = router.currentRoute.query.ts
      const actualTime = Math.floor(Date.now() / 1000)

      const tokenHasExpired = (actualTime - timestamp) > 900

      if (token && !tokenHasExpired) {
        commit('setToken', token)
        return true
      }

      return false
    },

    logout({ commit }) {
      Vue.$cookies.remove('token')
      router.push({ path: '/login' })
      commit('setToken', '')
      commit('setDealers', '')
    },

    async requestLogin({ state, dispatch }, { email, password }) {
      const loginUrl = state.endpoint + '/login'

      try {
        const { data } = await axios.post(loginUrl, { email, password })

        // Set Token
        dispatch('setToken', data)

        // Get Rep data
        await dispatch('validateToken')


        router.push({ path: '/my-dealers' })
      } catch (error) {
        throw new Error(error.response.data)
      }
    },

    async getDealers({ state, commit }, { sortBy, page, searchQuery }) {
      const url = state.endpoint + '/dealers'

      const params = {
        api_token: state.token,
        sort_by: sortBy,
        page
      }

      if (searchQuery.length > 0) params['search_query'] = searchQuery;

      try {
        const response = await axios.get(url, { params })
        commit('setDealers', response.data.data)
        commit('setDealersTotalPages', response.data.meta.last_page)
        commit('setDealersTotalItems', response.data.meta.total)

      } catch (error) {
        console.log(error.response);
      }
    }
  },

  mutations: {
    setToken: (state, token) => state.token = token,
    setRep: (state, repData) => state.rep = repData,
    setDealers: (state, dealersData) => state.dealers = dealersData,
    setDealersTotalPages: (state, totalPages) => state.dealersTotalPages = totalPages,
    setDealersTotalItems: (state, totalItems) => state.dealersTotalItems = totalItems,
  },

  modules: {
  }
})
