<template>
  <div
    id="kt_header"
    class="kt-header kt-grid__item kt-header--fixed"
    data-ktheader-minimize="on"
  >
    <div class="kt-header__bottom">
      <div class="kt-container m-auto">
        <!-- Open button -->
        <div class="open-button-container">
          <button
            v-if="!isNavbarVisible"
            class="kt-header-menu-wrapper-close"
            :class="{ close: isNavbarVisible }"
            id="kt_header_menu_mobile_open_btn"
            @click="toggleNavbarView(true)"
          >
            <p>Menu</p>
            <div class="lines-container">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </button>
        </div>

        <!-- FSG logo -->
        <figure>
          <router-link :to="'/'">
            <img
              class="gamemplan-logo-mobile fsg-logo"
              src="/images/fsg-logo.png"
              alt="Founder Sport Group Logo"
            />
          </router-link>
        </figure>

        <!-- Gameplan logo -->
        <figure>
          <router-link :to="'/'">
            <img
              class="gamemplan-logo-mobile"
              src="/images/logo.svg"
              alt="Gameplan Logo"
            />
          </router-link>
        </figure>

        <!-- List of links and Close button -->
        <div
          class="kt-header-menu-wrapper"
          :class="{ visible: isNavbarVisible }"
          id="kt_header_menu_wrapper"
        >
          <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile">
            <!-- Close button -->
            <button
              class="kt-header-menu-wrapper-close close"
              id="kt_header_menu_mobile_close_btn"
              @click="toggleNavbarView(true)"
            >
              <i class="la la-close"></i>
            </button>

            <!-- Links -->
            <ul class="kt-menu__nav">
              <template v-if="repIsLoggedIn">
                <HeaderMenuItem
                  v-for="(item, index) in menu_items"
                  :key="index"
                  :title="item.title"
                  :link="item.link"
                  :sup="item.sup"
                  @click-item="toggleNavbarView"
                />

                <!--  Link to create projects -->
                <li class="kt-menu__item">
                  <a
                    class="kt-menu__link kt-menu__toggl"
                    target="_blank"
                    :href="createProjectLink"
                  >
                    <span class="kt-menu__link-text">Create New Project</span>
                    <img
                    class="external-likn-img"
                      src="/images/external-link-menu.png"
                      :alt="'external link to impersonate as ' + rep.full_name"
                    />
                  </a>
                </li>

                <!-- Logout -->
                <li
                  class="kt-menu__item"
                  aria-haspopup="true"
                  @click="$emit('click-item')"
                >
                  <a
                    target="_blank"
                    class="kt-menu__link kt-menu__toggle"
                    aria-haspopup="true"
                    @click="logout"
                  >
                    <span class="kt-menu__link-text">Logout</span>
                  </a>
                </li>
              </template>

              <template v-else>
                <HeaderMenuItem
                  :title="'Rep Access'"
                  :link="'/login'"
                  @click-item="toggleNavbarView"
                  :isLoginLink="true"
                />
              </template>
            </ul>
          </div>
        </div>

        <!-- end: Header Menu -->
      </div>
      <div class="gradient-bar"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderMenuItem from "@/components/Header/HeaderMenuItem.vue";

export default {
  name: "HeaderMenu",
  data() {
    return {
      menu_items: [
        { title: "My Profile", link: "/profile" },
        { title: "My Dealers", link: "/my-dealers" },
      ],
      isNavbarVisible: false,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    toggleNavbarView(comesFromButton = false) {
      if (this.isNavbarVisible || comesFromButton) {
        this.isNavbarVisible = !this.isNavbarVisible;
      }
    },
  },
  components: {
    HeaderMenuItem,
  },
  computed: {
    ...mapState(["token", "rep"]),

    actual_route() {
      return this.$route.path;
    },

    createProjectLink() {
      return this.rep.create_new_project_url;
    },

    repIsLoggedIn() {
      return this.token.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
#kt_header {
  .kt-header__bottom {
    background-color: black;
    display: flex;
    flex-flow: column;
    height: auto;
    .gradient-bar {
      background-image: url("/images/gradient-bar.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 5px;
      width: 100%;
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      -ms-filter: "FlipH";
      filter: FlipH;
    }

    .kt-header-menu-wrapper {
      background-color: unset;
      padding: 0;
      width: 30%;

      &.visible {
        left: 0;
      }
      .showroom-logo {
        width: 200px;
        cursor: pointer;
      }
      .kt-menu__nav {
        #login-link {
          margin-left: auto;
        }
        @media screen and (max-width: 1024px) {
          padding: 20px 0 30px 0;
        }
        .kt-menu__item {
          &:hover {
            ::v-deep.kt-menu__link {
              @media screen and (max-width: 1024px) {
                span {
                  color: black;
                }
              }
            }
          }
          ::v-deep.kt-menu__link {
            @media screen and (max-width: 1500px) {
              padding: 0;
            }

            @media only screen and (min-width: 1025px) {
              border-right: 1px solid #444444;
            }
            border-radius: 0;
            padding: 0 20px;
            @media only screen and (max-width: 1500px) {
              padding: 0 12px;
            }
            @media only screen and (max-width: 1280px) {
              padding: 0 5px;
            }
            span {
              color: white;
              @media only screen and (max-width: 1280px) {
                font-size: 12px;
              }
            }
            .kt-menu__link-text {
              font-family: "Forza";

              @media screen and (max-width: 1024px) {
                display: block;
                text-align: center;
                height: 50%;
                margin: auto;
              }
            }
          }
          &:last-child {
            ::v-deep.kt-menu__link {
              border-right: none;
            }
          }
          &.kt-menu__item--open {
            ::v-deep.kt-menu__link {
              background-color: unset;
              @media screen and (max-width: 1024px) {
                &,
                &:hover {
                  background-color: white;
                  color: black !important;
                  span.kt-menu__link-text {
                    color: black !important;
                    font-family: "Forza";
                  }
                }
              }

              span {
                @media screen and (min-width: 1025px) {
                  padding-top: 1px;
                  border-bottom: 1px solid white;
                }
              }
            }
          }
        }
      }
      .kt-header-toolbar {
        padding: 0;
        .btn {
          padding: 10px 20px;
          font-family: "ForzaBold";
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          @media only screen and (max-width: 1280px) {
            padding: 10px;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100vh;
        background-color: black;
      }
    }
    .external-likn-img{
      height: 16px;
      width: 16px;
      margin: auto;
    }
  }
}

.header-menu-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

figure {
  display: flex;
  height: 100%;
  width: 35%;
  justify-content: center;
  align-items: center;
  margin: 0;
  .gamemplan-logo-mobile {
    display: block;
    width: 240px;
    margin: auto;

    &.fsg-logo {
      width: auto;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
}

#create-project-desktop-button {
  display: none;
  @media screen and (min-width: 1025px) {
    display: block;
  }
}

#create-project-mobile-button {
  display: none;
}

#create-project-desktop-button,
#create-project-mobile-button {
  background: transparent;
  /* font-family: "SlateStd" !important; */
  border: 2px solid white;
  text-transform: capitalize !important;
  border-radius: 3px;

  &:hover {
    background: white;
    color: black;
  }
}

@media screen and (max-width: 1024px) {
  .kt-container {
    width: 100vw;
    height: 50px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #kt_header_menu_mobile_open_btn,
  #kt_header_menu_mobile_close_btn {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    width: 40px;
    height: 40px;
    position: initial;
    top: 0;
    top: 1px;
    left: 1rem;
    background: black;
    border: 1px solid white;
    z-index: 1002;
    transition: left 0.3s ease, right 0.3s ease;
    /* position: fixed; */
    border: 0;
    box-shadow: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none !important;

    padding: 15px;

    &.close {
      background: rgb(252, 61, 61);
      justify-content: center;
      opacity: 1;
      i.la.la-close {
        color: white;
      }
    }

    .line {
      width: 15px;
      height: 2px;
      background-color: white;
    }
  }

  .open-button-container {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    #kt_header_menu_mobile_open_btn {
      position: relative;
      width: 30%;
      padding: 15px 0;
      font-family: Forza;
      margin: initial;
      p {
        color: white;
        margin: 0;
        margin-right: 0.7rem;
        font-family: "ForzaBold";
        text-transform: uppercase;
      }
      .lines-container {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  figure {
    display: flex;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin: 0;
    .gamemplan-logo-mobile {
      display: block;
      min-width: 145px;

      @media screen and (max-width: 576px) {
        width: 145px;
      }
      margin: auto;
    }
  }

  #create-project-mobile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 75%;
  }
  .header-menu-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #kt_header_menu {
    background-color: black;
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      #kt_header_menu_mobile_close_btn {
        margin-left: 15px;
        margin-top: 10px;
      }
    }
  }

  li.kt-menu__item.kt-menu__item--rel {
    display: flex;
    border-bottom: 1px solid grey;
    justify-content: center;
    align-items: center;
    flex-flow: row;

    a {
      span {
        display: block;
        text-align: center;
        height: 50%;
        margin: auto;
      }
    }
  }
}
</style>