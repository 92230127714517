<template>
  <li
    class="kt-menu__item"
    :class="{
      'kt-menu__item--open kt-menu__item--here kt-menu__item--rel kt-menu__item--open kt-menu__item--here': is_active,
      'kt-menu__item kt-menu__item--rel': !is_active,
      'login-link': isLoginLink,
    }"
    aria-haspopup="true"
    @click="$emit('click-item')"
  >
    <router-link :to="link" class="kt-menu__link kt-menu__toggle">
      <span class="kt-menu__link-text"
        >{{ title }}<sup v-if="sup">{{ sup }}</sup></span
      >
      
    </router-link>
  </li>
</template>

<script>
export default {
  name: "HeaderMenuItem",
  props: ["title", "link", "sup", "isLoginLink"],
  computed: {
    is_active() {
      return this.link === this.$route.path;
    },
    l() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" >
sup {
  left: 0.2rem;
  font-style: oblique;
}

li.login-link {
  margin-left: auto;
}

.kt-menu__item {
  transition: all 0.2s;

  &:hover a {
    background: rgba(255, 255, 255, 0.932) !important;
    span {
        color: black !important;
    }
  }
}
</style>